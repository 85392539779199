export const STORE_PREFIX = process.env.NEXT_PUBLIC_STORE_PREFIX;
export const STORE_ID = process.env.NEXT_PUBLIC_STORE_ID;
export const RAZORPAY_KEY = process.env.NEXT_PUBLIC_RAZORPAY_KEY;
export const RAZORPAY_SCRIPT = "https://checkout.razorpay.com/v1/checkout.js";
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const MEDIA_BASE_URL = process.env.NEXT_PUBLIC_MEDIA_URL;
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const HOTJAR_SNIPPET_VERSION =
  process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION;
export const OPTIMONK_ID = process.env.NEXT_PUBLIC_OPTIMONK_ID;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const CUSTOMER_GLU_KEY = process.env.NEXT_PUBLIC_CUSTOMER_GLU_KEY;
export const WISEPOPS_KEY = process.env.NEXT_PUBLIC_WISEPOPS_KEY;
export const GOOGLE_VERIFICATION_TAG =
  process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_TAG;
export const AWS_CLIENT_ID = process.env.NEXT_PUBLIC_AWS_CLIENT_ID;
export const AFFISE_ENABLED = process.env.NEXT_PUBLIC_AFFISE_ENABLED;
export const LIMECHAT_ENABLED =
  process.env.NEXT_PUBLIC_LIMECHAT_ENABLED === "true";
export const LIMECHAT_WEBSITE_TOKEN =
  process.env.NEXT_PUBLIC_LIMECHAT_WEBSITE_TOKEN;
export const LIMECHAT_BASE_URL = process.env.NEXT_PUBLIC_LIMECHAT_BASE_URL;
export const WORDPRESS_URL = process.env.NEXT_PUBLIC_WORDPRESS_URL;
export const WORDPRESS_MEDIA_URL = process.env.NEXT_PUBLIC_WORDPRESS_MEDIA_URL;
export const WORDPRESS_IP = process.env.NEXT_PUBLIC_WORDPRESS_IP;
export const WORDPRESS_AUTH = process.env.NEXT_PUBLIC_WORDPRESS_AUTH;
export const GOKWIK_MID = process.env.NEXT_PUBLIC_GOKWIK_MID;
export const KWIKPASS_MID = process.env.NEXT_PUBLIC_KWIKPASS_MID;
export const GOKWIK_SCRIPT = process.env.NEXT_PUBLIC_GOKWIK_SCRIPT;
export const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
export const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST;
export const POSTHOG_FLAG = process.env.NEXT_PUBLIC_POSTHOG_FLAG;
export const VERCEL_AB_FLAG = process.env.NEXT_PUBLIC_AB_FLAG;
export const AB_THRESHOLD = process.env.NEXT_PUBLIC_AB_THRESHOLD;
export const STORE_ENV = process.env.STORE_ENV;
